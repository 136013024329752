<template>
  <div class="view">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-0 view-block">
        <div v-if="loading" class="row margin-0">
          <div class="col-md-5 col-12 pad-10">
            <h4 class="margin-bottom-10 visible-xs" v-if="product">{{product.title}}</h4>
            <div class="preview-img-block">
              <img src="" class="img-fluid preview-img shine" style="height:50vh;width:80%;" alt="">
            </div>
          </div>
          <div class="col pad-15">
            <h4 class="margin-bottom-30 hidden-xs shine pad-15" style="max-width:70%;"></h4>
            <!-- <span class="s-head">Price</span> -->
            <h5 class="margin-bottom-20 light-weight shine pad-15" style="max-width:30%;"></h5>
            <h5 class="margin-bottom-20 light-weight shine pad-10" style="max-width:60%;"></h5>
            <h5 class="margin-bottom-20 light-weight shine pad-10" style="max-width:40%;"></h5>
            <h5 class="margin-bottom-40 light-weight shine pad-10" style="max-width:50%;"></h5>
            <h5 class="margin-bottom-20 light-weight shine pad-40" style="max-width:80%;"></h5>
          </div>
        </div>
        <div v-else class="row margin-0">
          <div class="col-md-7 col-12 pad-10" style="border-right: 1px solid #ddd;">
            <!-- <span class="featured-adb">Featured</span> -->
            <div class="preview-img-block">
              <img :src="preview_image?preview_image:'/noimage.jpg'" class="img-fluid preview-img" alt="">
            </div>
            <div>
                <ul class="thumb-list">
                  <template v-for="(item, index) in images">
                      <li @click="preview_image=item" :key="index" :class="{'active':preview_image===item}">
                          <img :src="item" alt="">
                      </li>
                  </template>
              </ul>
            </div>
            <div class="pad-10">
              <h4 class="margin-bottom-10">{{product.title}}</h4>
              <span class="s-head">Price</span>
              <h5 class="margin-bottom-20 light-weight" style="color:orangered;"> <span class="fa fa-inr"></span> {{product.price}}</h5>
              <!-- <span class="s-head bold">Description</span>
              <p class="txt-grey">{{product.description}}</p> -->
            </div>
          </div>
          <div class="col-md-5 col-12 pad-15">
            <h4 class="margin-bottom-10 margin-top-10">Reach more people with Feature Ad</h4>
            <p class="txt-grey"></p>
            <div class="plans-block">
              <h5>Feature Ad advantages</h5>
              <p class="txt-grey">- Your ad will reach <b>10x</b> users <br>
              <!-- - More benefited with featured ad <br> -->
              - Always become front line<br>
              - Never miss the impression of a user<br>
              </p>
              <div class="row margin-0">
                <div class="col-12 pad-10" style="background-color: #efefef;">
                  <ul class="plans-list list-inline">
                    <template v-for="(plan, idx) in plans">
                      <li :key="idx" @click="payload={
                          plan: plan.plan,
                          start_on: new Date(),
                          days: plan.days,
                          price: plan.price,
                          end_on: null,
                          data: {}
                        }" :class="(payload && payload.plan===plan.plan)?'active':''">
                        <div class="row margin-0">
                          <div class="col-12 pad-0">
                            <h4 class="margin-bottom-0">{{plan.label}}</h4>
                            <template v-for="(fe, idd) in plan.features">
                              <span :key="idd">- {{fe}}<br></span>
                            </template>
                            <h3 class="margin-top-5 margin-bottom-0">&#8377;{{plan.price}} <span class="txt-grey" style="text-decoration: line-through;font-size:0.9em;"> &#8377;{{plan.original_price}}</span></h3>
                            <span class="d-block text-right">{{plan.days}} days</span>
                          </div>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <div class="pad-top-20">
              <button class="btn btn-success w-100" :disabled="payload.price<1" @click="createFeatured()"><b>PAY <span class="fa fa-inr"></span> {{payload.price}}</b></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
        images: [],
        preview_image: null,
        product: null,
        plans: [],
        loading: true,
        visible_contact: false,
        payload: {
          start_on: new Date(),
          plan: null,
          days: null,
          price: null,
          end_on: null,
          data: {}
        },
        payment_detail: null
    }
  },
  watch: {
    'payload.days': function() {
      // this.calculateDate();
    }
  },
  created() {
    this.getProduct();
    this.getProductPlans();
    // this.calculateDate();
  },
  methods: {
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    calculateDate() {
      this.payload.price = this.payload.days>0?(this.payload.days * 6) - 1:0;
      this.payload.end_on = this.addDays(new Date(), parseInt(this.payload.days));
    },
    getProduct() {
      this.loading = true;
      this.$cs.product.list({
        resource: `${this.$cs.product.api}${this.id}/`
      })
      .then(res => {
        this.product = res.data;
        this.images = this.product.images;
        if(this.images.length>0) {
          this.preview_image = this.images[0];
        }
        this.loading = false;
      })
    },
    getProductPlans() {
      this.$cs.product.list({
        resource: `${this.$cs.myproduct.api}plans/`
      })
      .then(res => {
        this.plans = res.data;
        if(res.data && res.data.length > 0) {
          this.payload = {
            plan: res.data[0].plan,
            start_on: new Date(),
            days: res.data[0].days,
            price: res.data[0].price,
            end_on: null,
            data: {}
          }
        }
      })
    },
    createFeatured(item) {
      if (this.validate(item)) {
          const that = this;
          that.$cs.myproduct.create({
            resource: `${that.$cs.myproduct.api}${that.id}/featured/`,
            data: that.payload
          })
          .then(res => {
              console.log(res.data);
              this.payment_detail = res.data;
              var options = res.data.payment_link.payment_link
              // after payment success handler
              options.handler = function (response) {
              response.featured_id = options.notes.featured_id
              let resource = `${that.$cs.myproduct.api}${that.id}/paymentsucess/`;
              that.$cs.myproduct.update({
                  resource: resource,
                  data:response
              })
              .then(res => {
                  that.$router.push('/my-ads/')
              }, err=> {
                  console.log(err.response);
              })
              }
              options.modal = {
                  ondismiss: function(response) {
                    console.log(response, "Checkout form closed");
                  }
                };
              // Razorpay pop for payment
              var rzp = new Razorpay(options)
              rzp.open()
              event.preventDefault()
          },
          err => {
              console.log(err.response.data);
          })
      }
    },
    validate() {
      let proceed = true;
      return proceed;
    }
  }
};
</script>
<style lang="stylus" scoped>
.plans-block
  margin-top 30px
  padding 10px
  border 1px solid #dddddd
  .form-group
    label
      font-weight 500
.view {
  padding: 5vh 0vh;
  min-height 92vh
  .view-block {
    background-color: #ffffff;
    border: 1px solid #dddddd;
  }
}
.preview-img-block
  height:55vh;
  display:flex;
  text-align:center;
  align-items:center;
  justify-content:center;
  .preview-img
    max-height 40vh
.thumb-list
    list-style none
    margin 0px
    padding 0px
    width 100%
    max-width 100%
    overflow-x scroll
    li
        width 5vw
        height 5vw
        max-height 5vw
        border 1px solid #ddd
        margin 0px 10px
        float left
        cursor pointer
        display flex
        text-align center
        align-items center
        justify-content center
        &.active
            border-color red
        img
            max-width 100%
            max-height 5vw
.eye-outline
  color #0594d9
  cursor pointer
.btn-light
  border 1px solid #999
  margin-top 5px
  margin-bottom 5px
@media (max-width: 767px)
  .view
    padding-top 2vh
  .preview-img-block
    height:35vh;
    .preview-img
      max-height 30vh
  .thumb-list
    li
      width 10vw
      height 10vw
      max-height 10vw
      margin 0px 5px
.plans-list
  list-style none
  padding 0
  margin 0
  li
    border 1px solid #ddd
    padding 8px
    margin-bottom 10px
    border-radius 4px
    cursor pointer
    &.active
      border-color #000
</style>
